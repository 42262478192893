
import ClienteService from "../../../Services/ClienteService";
export default {
  name: "datos-smtp-detalle",
  data() {
    return {
      clienteObj: {
        smtp_personalizado: false,
        smtp_remitente_global: false,
      },
      clienteOriginalObj: {},
      paisOpciones: [],
      cifradoOpciones: [
        { label: "Ninguno", value: null },
        { label: "TLS", value: "tls" },
        { label: "SSL", value: "ssl" },
      ],
      estadoOriginalOpciones: [],
      estadoOpciones: [],
      zonaHorariaOpciones: [],
      ladas: this.constantesLadas.Ladas,
    };
  },
  computed: {
    clienteEditado() {
      return (
        JSON.stringify(this.clienteObj) ==
        JSON.stringify(this.clienteOriginalObj)
      );
    },
    editarServidor() {
      return this.clienteObj.smtp_personalizado;
    },
    servidorClaseInput() {
      return this.clienteObj.smtp_personalizado
        ? "input-formulario"
        : "input-formulario-disable";
    },
    servidorClaseInputContenido() {
      return this.clienteObj.smtp_personalizado
        ? "input-formulario-contenido"
        : "input-formulario-disable-contenido";
    },
    servidorClaseSelect() {
      return this.clienteObj.smtp_personalizado
        ? "select-formulario"
        : "input-formulario-disable";
    },
    editarRemitente() {
      return this.clienteObj.smtp_remitente_global;
    },
    remitenteClaseInput() {
      return this.clienteObj.smtp_remitente_global
        ? "input-formulario"
        : "input-formulario-disable";
    },
    remitenteClaseInputContenido() {
      return this.clienteObj.smtp_remitente_global
        ? "input-formulario-contenido"
        : "input-formulario-disable-contenido";
    },
  },
  mounted() {
    this.cargaInicial();
  },
  methods: {
    async cargaInicial() {
      this.loader(true);
      await this.obtenerDetalle(false);
      this.loader(false);
    },
    async obtenerDetalle(loader = true) {
      if (loader) this.loader(true);
      await ClienteService.obtenerDatosSMTP({})
        .then((resp) => {
          this.clienteObj = resp;
          this.clienteOriginalObj = _.cloneDeep(this.clienteObj);
          if (loader) this.loader(false);
        })
        .catch((error) => {
          if (loader) this.loader(false);
          this.mostrarAlerta(error, "error");
        });
    },
    async editar(loader = true) {
      if (loader) this.loader(true);
      let datos = this.armarDatosEditar();
      if (typeof datos != "object") return this.loader(false);
      await ClienteService.editarDatosSMTP(datos)
        .then(async (resp) => {
          await this.obtenerDetalle(false);
          this.mostrarAlerta(resp.mensaje, "exito");
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          if (loader) this.loader(false);
        });
    },
    cancelar() {
      this.clienteObj = _.cloneDeep(this.clienteOriginalObj);
    },
    armarDatosEditar() {
      let datos = {
        smtpPersonalizado: this.clienteObj.smtp_personalizado ? 1 : 0,
        smtpRemitenteGlobal: this.clienteObj.smtp_remitente_global ? 1 : 0,
      };
      // Datos de servidor
      if (this.clienteObj.smtp_personalizado) {
        datos.smtpServidor = this.clienteObj.smtp_servidor;
        datos.smtpUsuario = this.clienteObj.smtp_usuario;
        datos.smtpContrasenia = this.clienteObj.smtp_contrasenia || "";
        datos.smtpCifrado = this.clienteObj.smtp_cifrado;
        datos.smtpPuerto = this.clienteObj.smtp_puerto;
      }

      // Datos de CC
      let correosValidos = true;
      if (this.clienteObj.smtp_cc) {
        correosValidos = this.validarCorreosCC();
      }
      if (!correosValidos) return false;
      datos.smtpCc =
        this.clienteObj.smtp_cc != null ? this.clienteObj.smtp_cc : "";

      // Datos de Cuenta
      if (this.clienteObj.smtp_remitente_global) {
        datos.smtpRemitenteNombre = this.clienteObj.smtp_remitente_nombre;
        datos.smtpRemitenteEmail = this.clienteObj.smtp_remitente_email;
      }
      return datos;
    },
    regresarPrimerElemento() {
      document.querySelector('[tabindex="1"]').focus();
    },
    habilitarEdicionServidor(val) {
      if (val) this.$nextTick(() => this.$refs.inputServidor.focus());
      else {
        this.clienteObj.smtp_servidor = this.clienteOriginalObj.smtp_servidor;
        this.clienteObj.smtp_usuario = this.clienteOriginalObj.smtp_usuario;
        this.clienteObj.smtp_password = this.clienteOriginalObj.smtp_password;
        this.clienteObj.smtp_cifrado = this.clienteOriginalObj.smtp_cifrado;
        this.clienteObj.smtp_puerto = this.clienteOriginalObj.smtp_puerto;
        this.clienteObj.smtp_personalizado = val;
      }
    },
    habilitarEdicionRemitente(val) {
      if (val) this.$nextTick(() => this.$refs.inputRemitente.focus());
      else {
        this.clienteObj.smtp_remitente_nombre =
          this.clienteOriginalObj.smtp_remitente_nombre;
        this.clienteObj.smtp_remitente_email =
          this.clienteOriginalObj.smtp_remitente_email;
        this.clienteObj.smtp_remitente_global = val;
      }
    },
    validarCorreosCC() {
      let correosCc = this.clienteObj.smtp_cc.replaceAll(" ", "");
      if (correosCc.substring(correosCc.length - 1) == ",") {
        this.mostrarAlerta(
          "Omite el uso de coma (,) al final de los correos CC",
          "error"
        );
        return false;
      }
      let correosValidos = true;
      correosCc = correosCc.split(",");
      correosCc.forEach((c) => {
        if (this.validarCorreo(c) != true) {
          this.mostrarAlerta(`El correo "${c}" es un correo inválido`, "error");
          correosValidos = false;
        }
      });
      return correosValidos;
    },
  },
};
