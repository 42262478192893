import RepoUtils from "./Request/RepoUtils";

export default {
  // GET
  obtenerDetalle: (filtros) =>
    RepoUtils.ejecutarPeticionGet(filtros, "clientes", "obtenerDetalle"),
  obtenerDatosSMTP: (filtros) =>
    RepoUtils.ejecutarPeticionGet(filtros, "clientes", "obtenerDatosSMTP"),
  obtenerConcentradoOpciones: (filtros) =>
    RepoUtils.ejecutarPeticionGet(
      filtros,
      "clientes",
      "obtenerConcentradoOpciones"
    ),

  // POST
  editar: (data) => RepoUtils.ejecutarPeticionPost(data, "clientes", "editar"),
  editarDatosSMTP: (data) =>
    RepoUtils.ejecutarPeticionPost(data, "clientes", "editarDatosSMTP"),
  actualizarFoto: (data) =>
    RepoUtils.ejecutarPeticionPost(data, "clientes", "actualizarFoto"),
};
